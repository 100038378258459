.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 1200px;
}
.platformSection {
    width: 100%;
    height: 700px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
}
.platformSection img {
    width: 260px;
}
.platformSectionWrapper {
    width: 800px;
    display: flex;
    justify-content: space-between;
}
.platformSectionContentItem {
    height: 436px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.platformSectionContentItemTitle {
    font-size: 48px;
    font-weight: 500;
    line-height: 67px;
    color: rgba(255, 255, 255, 1);
}
.btnList {
    margin-top: 32px;
    display: flex;
    margin-bottom: 27px;
}
.btn {
    margin-right: 16px;
    width: 150px;
    height: 40px;
    line-height: 40px;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 1);
    font-size: 14px;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    text-align: center;
}
.btn:hover, .btn:active {
    background: rgba(255, 255, 255, 1);
    color: rgba(0, 0, 0, 1);
}

.goodsSection {
    width: 1200px;
}
.goodsSectionTitle {
    font-size: 32px;
    font-weight: 500;
    color: rgba(33, 35, 38, 1);
    margin-top: 124px;
    margin-bottom: 36px;
}
.goodsSectionContent {
    display: flex;
    flex-wrap: wrap;
    min-height: 375px;
    max-height: 750px;
    overflow-y: auto;
}
.goodsSectionContentItem {
    width: calc(20% - 13px);
    height: 342px;
    margin-right: 16px;
    margin-bottom: 25px;
    position: relative;
    cursor: pointer;
}
.goodsSectionContentItem:nth-child(5n) {
    margin-right: 0;
}
.goodsSectionContentItemCover img {
    width: 100%;
    height: 227px;
    border-radius: 2px;
    background: #ccc;
    object-fit: cover;
}
.goodsSectionContentItemName {
    margin-top: 16px;
    margin-bottom: 8px;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    color: rgba(33, 35, 38, 1);
    height: 38px;
    line-height: 19px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}
.goodsSectionContentItemPrice {
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.goodsSectionContentItemSellingPrice {
    font-size: 18px;
    font-weight: 400;
    color: rgba(255, 139, 0, 1);
    margin-right: 17px;
}
.goodsSectionContentItemOriginalPrice {
    font-size: 18px;
    font-weight: 400;
    color: rgba(229, 229, 229, 1);
    text-decoration: line-through;
}
.goodInfo {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    border-radius: 2px;
    background: rgba(0, 0, 0, 0.4);
}
.goodInfoTip {
    margin-top: 15px;
}
.goodInfo .miniCodePic {
    width: 100px;
    height: 100px;
    border-radius: 8px;
    background: #ffffff;
}
.goodInfo .loading :global .ant-spin-container::after {
    width: 100px;
    height: 100px;
    border-radius: 8px;
}
.goodInfo :global .ant-spin-nested-loading {
    width: 100px;
    height: 100px;
    border-radius: 8px;
}
