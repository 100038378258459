.wrapper {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 168px);
}
.bg {
    position: absolute;
    z-index: -1;
    height: 100%;
    width: 100%;
}
.main {
    display: flex;
    height: 436px;
    align-items: center;
    font-size: 48px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 67.2px;
    color: rgba(34, 34, 34, 1);
}
.phone {
    width: 260px;
    margin-right: 106px;
}
.content {

}
.btn {
    width: 150px;
    height: 40px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    background: rgba(0, 0, 0, 1);
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.btn img {
    width: 30px;
    height: 30px;
    margin-right: 8px;
}
.wxBtn {
    background: #ffffff;
    border-radius: 50%;
}
.overPic {
    width:174px;
    height: 174px;
}
