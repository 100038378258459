.wrapper {
    width: 100%;
    min-width: 1200px;
}
.miniSection {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 705px;
    background: no-repeat center;
    background-size: cover;
}
.phoneIcon {
    margin-left: 237px;
    margin-right: 165px;
    width: 250px;
}
.miniTipPic {
    width: 572px;
    margin-left: 80px;
}
.miniContent {
    display: flex;
}
.miniTitle {
    font-size: 32px;
    font-weight: 500;
    color: rgba(34, 34, 34, 1);
    margin-bottom: 48px;
}
.miniContentList {
    display: flex;
    flex-direction: column;
}
.miniContentItem {
    margin-bottom: 24px;
    cursor: pointer;
}
.divide {
    width: 2px;
    height: 81px;
    background-color: rgba(165, 165, 165, 0.2);
    margin-right: 3px;
}
.miniContentItemTitle {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 25px;
    color: rgba(153, 153, 153, 1);
    margin-bottom: 16px;
}
.miniContentItemTips {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 22px;
    color: rgba(153, 153, 153, 1);
    width: 500px;
}
.miniContentItemActive .miniContentItemTitle {
    color: rgba(102, 194, 185, 1);
}
.miniContentItemActive .miniContentItemTips {
    color: rgba(51, 51, 51, 1);
}
.miniContentItemActive .divide {
    height: 50px;
    margin-top: 28px;
    position: relative;
}
.miniContentItemActive .divide::after {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    background-color: rgba(102, 194, 185, 1);
    border-radius: 50%;
    position: absolute;
    top: -22px;
    left: -2px;
}


.appProcessSection {
    width: 100%;
    height: 844px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url("../../assets/img/mini/bg3.png") no-repeat center;
    background-size: cover;
    flex-direction: column;
}
.appProcessTitle {
    font-size: 48px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 67.2px;
    color: rgba(255, 255, 255, 1);
    margin-bottom: 55px;
}
.appProcessContent {

}
.appProcessContent img {
    width: 1200px;
}


.spaceSection {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: no-repeat center;
    background-size: cover;
    flex-direction: column;
}
.spaceTitle {
    font-size: 48px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 67.2px;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 40px;
    margin-top: 75px;
}
.spaceBtnList {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}
.spaceBtnList span {
    width: 104px;
    height: 40px;
    line-height: 40px;
    opacity: 1;
    border-radius: 35px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(34, 34, 34, 1);
    text-align: center;
    cursor: pointer;
}
.spaceBtnList .active {
    color: #ffffff;
    background: black;
}
.spaceContent {

}
.spaceContentItem {
    display: flex;
    width: 1200px;
    justify-content: space-between;
}
.spaceContentItemTitleWrapper {
    margin-top: 67px;
}
.spaceContentItemTitle {
    width: 180px;
    font-size: 32px;
    font-weight: 500;
    color: rgba(34, 34, 34, 1);
    padding-bottom: 14px;
    border-bottom: 1px solid rgba(222, 222, 222, 1);
    margin-bottom: 30px;
    position: relative;
}
.spaceContentItemTitle::after {
    content: "";
    display: block;
    position: absolute;
    width: 36px;
    height: 4px;
    background: rgba(0, 0, 0, 1);
    top: 42px;
}
.spaceContentItemTips {
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 25.2px;
    color: rgba(51, 51, 51, 1);
    width: 420px;
}
.spaceContentItemTips img {
    width: 18px;
    margin-right: 14px;
}
.sellGoodPic {
    width: 741px;
    height: 420px;
}


.appDownloadSection {
    width: 100%;
    height: 458px;
    background: rgba(245, 243, 243, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.appDownloadTitle {
    font-size: 48px;
    font-weight: 500;
    color: rgba(51, 51, 51, 1);
    margin-bottom: 48px;
}
.appDownloadContent {
    display: flex;
    justify-content: space-between;
    width: 444px;
}
.appDownloadContentItem {
    text-align: center;
}
.appDownloadContentItem img {
    width: 140px;
}
.appDownloadContentItemTitle {
    font-size: 18px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    text-align: center;
    margin-top: 17px;
    margin-bottom: 14px;
}
.appDownloadContentItemTips {
    font-size: 14px;
    font-weight: 400;
    color: rgba(153, 153, 153, 1);
    text-align: center;
    cursor: pointer;
}
