.wrapper {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.bg {
    position: absolute;
    z-index: -1;
    height: 100%;
    width: 100%;
}
.main {
    font-size: 34px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 47px;
    color: rgba(34, 34, 34, 1);
    text-align: center;
    padding-top: 56px;
}
.phone {
    width: 174px;
    margin-bottom: 60px;
    margin-left: -34px;
}
.content {

}
.btnList {
    margin-top: 40px;
    margin-bottom: 48px;
}
.btn {
    width: 88px;
    height: 88px;
    border-radius: 50%;
    flex-direction: column;
    font-size: 12px;
    font-weight: 400;
    background: rgba(0, 0, 0, .2);
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    line-height: 1;
}
.btn img {
    width: 40px;
    height: 40px;
    margin-bottom: 5px;
}
.codePic {
    width: 100px;
    border-radius: 5px;
}
