.wrapper {
    width: 100%;
}
.miniSection {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 479px;
    background: no-repeat center;
    background-size: cover;
}
.phoneIcon {
    width: 113px;
}
.miniTipPic {
    width: 100%;
}
.miniContent {
    display: flex;
    flex-direction: column;
    padding: 0 33px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}
.miniTitle {
    font-size: 32px;
    font-weight: 500;
    color: rgba(34, 34, 34, 1);
    margin-bottom: 48px;
}
.miniContentList {
    display: flex;
    flex-direction: column;
}
.miniContentItem {
    margin-bottom: 24px;
    cursor: pointer;
}
.miniContentItemTitle {
    margin-bottom: 16px;
    font-size: 28px;
    font-weight: 500;
    line-height: 39px;
    color: rgba(34, 34, 34, 1);
    text-align: center;
    margin-top: 56px;
}
.miniContentItemTips {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: rgba(51, 51, 51, 1);
    text-align: center;
    margin-bottom: 36px;
    padding: 0 33px;
}
.miniContentItemPicWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 33px;
    margin-bottom: 15px;
}

.appProcessSection {
    width: 100%;
    height: 335px;
    background: url("../../assets/img/mini/bg3.png") no-repeat center;
    background-size: cover;
    display: flex;
    flex-direction: column;
}
.appProcessTitle {
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 39px;
    color: rgba(255, 255, 255, 1);
    margin-top: 56px;
    margin-bottom: 24px;
    text-align: center;
}
.appProcessContent {
    padding: 0 15px;
    display: flex;
    align-items: center;
}
.appProcessContent img {
    width: 100%;
}


.spaceSection {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url("../../assets/img/mini/mobile_bg2.png") no-repeat center;
    background-size: cover;
    flex-direction: column;
}
.spaceTitle {
    font-size: 28px;
    font-weight: 500;
    line-height: 39px;
    color: rgba(34, 34, 34, 1);
    margin-bottom: 40px;
    margin-top: 32px;
}
.spaceBtnList {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
}
.spaceBtnList span {
    width: 78px;
    height: 32px;
    line-height: 32px;
    border-radius: 35px;
    font-size: 14px;
    font-weight: 500;
    color: rgba(34, 34, 34, 1);
    text-align: center;
    cursor: pointer;
}
.spaceBtnList .active {
    color: #ffffff;
    background: black;
}
.spaceContent {

}
.spaceContentItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 24px;
}
.spaceContentItemTitleWrapper {
}
.spaceContentItemTitle {
    font-size: 16px;
    font-weight: 500;
    color: rgba(34, 34, 34, 1);
    margin-bottom: 8px;
    line-height: 23px;
}

.spaceContentItemTips {
    text-align: left;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    color: rgba(51, 51, 51, 1);
    margin-bottom: 48px;
}
.sellGoodPic {
    width: 100%;
}


.appDownloadSection {
    width: 100%;
    background: rgba(245, 243, 243, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.appDownloadTitle {
    font-size: 28px;
    line-height: 39px;
    font-weight: 500;
    color: rgba(51, 51, 51, 1);
    margin-top: 60px;
    margin-bottom: 48px;
}
.appDownloadContent {
    display: flex;
    flex-direction: column;
}
.appDownloadContentItem {
    text-align: center;
}
.appDownloadContentItem img {
    width: 100px;
}
.appDownloadContentItemTitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    color: rgba(51, 51, 51, 1);
    text-align: center;
    margin-top: 16px;
    margin-bottom: 8px;
}
.appDownloadContentItemTips {
    font-size: 14px;
    font-weight: 400;
    color: rgba(153, 153, 153, 1);
    text-align: center;
    margin-bottom: 60px;
    display: inline-block;
}
