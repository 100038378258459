.wrapper {
    width: 100%;
    height: 80px;
    background: #ffffff;
    box-shadow: 0px 2px 24px  rgba(0, 0, 0, 0.1);
}
.content {
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    position: relative;
}
.logoWrapper {
    height: 80px;
    font-size: 18px;
    font-weight: 500;
    color: rgba(51, 51, 51, 1);
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
}
.logo {
    width: 48px;
    height: 48px;
    margin-right: 8px;
}
.navList {
    font-size: 16px;
    font-weight: 400;
    border: none;
    height: 80px;
    margin: 0 auto;
    display: flex;
    align-items: center;
}
.navList :global .ant-menu-item, .navList :global .ant-menu-submenu {
    padding: 0 24px;
    height: 80px;
    line-height: 80px;
}
.navList :global .ant-menu-item-selected {
    font-weight: 500;
}
.navList :global .ant-menu-item-selected::after, .navList :global .ant-menu-item:hover::after,
.navList :global .ant-menu-submenu-selected::after, .navList :global .ant-menu-submenu:hover::after{
    border-bottom-width: 4px!important;
}
.navList :global .ant-menu-title-content a {
    color: inherit;
}
