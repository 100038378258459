.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 88px;
    background: rgba(0, 0, 0, 1);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    color: rgba(153, 153, 153, 1);
}
.infoItem {
    margin-right: 8px;
}
.infoItem:hover {
    color: rgba(255, 255, 255, 1);
}
.wrapper a {
    color: rgba(153, 153, 153, 1);
}
.wrapper a:active, .wrapper a:hover {
    color: rgba(255, 255, 255, 1);
}
.policeIcon {
    width: 24px;
    height: 24px;
}
.protocol {
    margin-left: 69px;
    display: flex;
    align-items: center;
    margin-right: 14px;
    cursor: pointer;
}
.protocol:hover {
    color: rgba(255, 255, 255, 1);
}
.wxIcon {
    margin: 0 12px;
    width: 40px;
    height: 40px;
    background: #ffffff;
    border-radius: 50%;
    cursor: pointer;
}
.icon {
    width: 40px;
    height: 40px;
    cursor: pointer;
}
.codeWrapper img {
    width: 140px;
    height: 140px;
}
.codeWrapper {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    align-items: center;
}
