.wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 298px;
    background: rgba(0, 0, 0, 1);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 17px;
    color: rgba(153, 153, 153, 1);
    padding: 30px 40px;
    box-sizing: border-box;
}
.infoItem {
    margin-bottom: 5px;
}
.infoItem:hover {
    color: rgba(255, 255, 255, 1);
}
.wrapper a {
    color: rgba(153, 153, 153, 1);
}
.wrapper a:active, .wrapper a:hover {
    color: rgba(255, 255, 255, 1);
}
.policeIcon {
    width: 16px;
    height: 16px;
}
.protocol {
    margin-top: 16px;
    display: flex;
    align-items: center;
    margin-right: 14px;
    cursor: pointer;
}
.protocol:hover {
    color: rgba(255, 255, 255, 1);
}
.codeWrapper {
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(102, 102, 102, 1);
    margin-bottom: 16px;
}
.codeWrapper img {
    width: 58px;
    height: 58px;
    padding: 6px;
    border-radius: 3px;
    background: #ffffff;
}
