.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.platformSection {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-size: cover;
}
.platformSection img {
    width: 174px;
    margin-top: 48px;
    margin-bottom: 60px;
}
.platformSectionWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.platformSectionContentItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.platformSectionContentItemTitle {
    font-size: 34px;
    font-weight: 500;
    line-height: 47px;
    color: rgba(255, 255, 255, 1);
    margin-top: 56px;
    text-align: center;
}
.btnList {
    margin-top: 32px;
    display: flex;
    margin-bottom: 27px;
}
.btn {
    margin-right: 16px;
    width: 96px;
    height: 32px;
    line-height: 32px;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 1);
    font-size: 12px;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    text-align: center;
}
.btn:hover, .btn:active {
    background: rgba(255, 255, 255, 1);
    color: rgba(0, 0, 0, 1);
}

.goodsSection {
    padding: 48px 16px;
}
.goodsSectionTitle {
    font-size: 24px;
    font-weight: 500;
    color: rgba(33, 35, 38, 1);
    margin-bottom: 36px;
    line-height: 33px;
}
.goodsSectionContent {
    display: flex;
    flex-wrap: wrap;
    min-height: 375px;
    overflow-y: auto;
}
.goodsSectionContentItem {
    width: calc(50% - 7.5px);
    height: 274px;
    margin-right: 15px;
    margin-bottom: 16px;
    position: relative;
    cursor: pointer;
}
.goodsSectionContentItem:nth-child(2n) {
    margin-right: 0;
}
.goodsSectionContentItemCover img {
    width: 100%;
    height: 165px;
    border-radius: 2px;
    background: #ccc;
    object-fit: cover;
}
.goodsSectionContentItemName {
    margin-top: 16px;
    margin-bottom: 8px;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    color: rgba(33, 35, 38, 1);
    line-height: 19px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}
.goodsSectionContentItemPrice {
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.goodsSectionContentItemSellingPrice {
    font-size: 18px;
    font-weight: 400;
    color: rgba(255, 139, 0, 1);
    margin-right: 17px;
}
.goodsSectionContentItemOriginalPrice {
    font-size: 18px;
    font-weight: 400;
    color: rgba(229, 229, 229, 1);
    text-decoration: line-through;
}
.goodInfo {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    border-radius: 2px;
    background: rgba(0, 0, 0, 0.4);
}
.goodInfoTip {
    margin-top: 15px;
}
.goodInfo img {
    width: 100px;
    height: 100px;
    border-radius: 8px;
}
.goodInfo .loading :global .ant-spin-container::after {
    width: 100px;
    height: 100px;
    border-radius: 8px;
}
.goodInfo :global .ant-spin-nested-loading {
    width: 100px;
    height: 100px;
    border-radius: 8px;
}
